import UserLogin from "components/auth/userLogin";
import { getProviders, getSession } from "next-auth/react";

export default function Home(props) {
  return <UserLogin {...props} />;
}

export async function getServerSideProps(context) {
  const providers = await getProviders();
  const session = await getSession(context);
  //console.log("context", context);
  return {
    props: {
      session,
      providers,
    },
  };
}
