import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Image from "next/image";
import { FormattedMessage } from "react-intl";

const RatingConfirmedModal = ({ show, handleClose }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="sm">
        <Modal.Body className="text-center" style={{padding: "0px 15px",paddingBottom:"20px"}}>
          <Image
            className="pt-3"
            src="/assets/images/stars.svg"
            width="174"
            height="79"
            alt="Stars"
          />
          <h3 className="text-primary mt-3">
            <FormattedMessage id="thankyou" defaultMessage="धन्यवाद !" />
          </h3>
          <p className="text-center">
            <FormattedMessage
              id="your_opinion"
              defaultMessage="आपकी राय मायने रखती है। आपके फ़ीड्बैक के लिए धन्यवाद"
            />
          </p>
          <Button variant="primary" onClick={handleClose}>
            <FormattedMessage id="continue_text" defaultMessage="जारी रखें" />
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RatingConfirmedModal;
