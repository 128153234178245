import React, { useState, useEffect } from 'react';

function SimpleTimer({ durationInSeconds, onComplete }) {
  const [secondsRemaining, setSecondsRemaining] = useState(durationInSeconds);

  useEffect(() => {
    let timerId;

    if (secondsRemaining > 0) {
      timerId = setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1);
      }, 1000);
    } else {
      onComplete();
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [secondsRemaining, onComplete]);
  const fmtMSS = (s) => {
    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
  }


  return (
    <span>
      ({fmtMSS(secondsRemaining)})
    </span>
  );
}

export { SimpleTimer };
