import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Image from "next/image";
import { FormattedMessage } from "react-intl";

const LoginSuccess = ({ show, onClick }) => {
  return (
    <>
      <Modal dialogClassName="regi_modal" show={show} centered>
        <Modal.Body>
          <div className="RegiModalBody">
            <Image
              src="/assets/images/check-icon.png"
              width="90"
              height="90"
              alt="Check Icon"
            />
            <h3>
              <FormattedMessage
                id="successfully_logged_in"
                defaultMessage="सफलतापूर्वक लॉगिन किया गया"
              />
            </h3>
            {/* <p>
              <FormattedMessage
                id="account_created_successfully"
                defaultMessage="बधाई!आपका अकाउंट बन गया है"
              />
            </p> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="button" onClick={onClick}>
            <FormattedMessage id="continue_text" defaultMessage="जारी रखें" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoginSuccess;
