import React, { useState, useEffect } from "react";

const CountDown = ({ duration = 5, onTimesup }) => {
  const [count, setCount] = useState(duration);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    return () => {
      clearInterval(timer); // Clean up the interval on component unmount
    };
  }, []);

  useEffect(() => {
    if (count === 0) {
      clearInterval();
      if (onTimesup) {
        onTimesup();
      }
    }
  }, [count, onTimesup]);

  const fmtMSS = (s) => {
    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
  };

  return <span className="otpcountdown">{`0${fmtMSS(count)}`}</span>;
};

export default CountDown;
