import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Image from "next/image";
import { FormattedMessage } from "react-intl";

const regiSuccess = ({ show, onClick }) => {
  return (
    <>
      <Modal dialogClassName="regi_modal" show={show} centered>
        <Modal.Body>
          <div className="RegiModalBody">
            <Image
              src="/assets/images/check-icon.png"
              width="90"
              height="90"
              alt="Check Icon"
            />
            <h3>
              <FormattedMessage
                id="registration_successful"
                defaultMessage="रजिस्ट्रेशन सफल रहा!"
              />
            </h3>
            <p>
              <FormattedMessage
                id="account_created_successfully"
                defaultMessage="बधाई!आपका अकाउंट बन गया है"
              />
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="button" onClick={onClick}>
            <FormattedMessage id="continue_text" defaultMessage="जारी रखें" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default regiSuccess;
